import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { CustomNavbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import MainContent from "./components/MainContent";
import { TwitterFeed } from "./components/TwitterFeed";
import DateFilterModal from "./components/DateFilterModal";
import { ClearFilterToast } from "./components/ClearFilterToast";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/App.css";

function App() {
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [isDateFilterModalOpen, setIsDateFilterModalOpen] = useState(false);

  useEffect(() => {
    // Initialize theme
    const savedTheme = localStorage.getItem("theme");
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setTheme(savedTheme || (prefersDarkMode ? "dark" : "light"));

    // Simulating content load
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const handleClearFilters = () => {
    // Your logic to clear filters goes here
    // ...

    // Then show the toast
    setShowToast(true);
  };

  const handleOpenDateFilterModal = () => {
    setIsDateFilterModalOpen(true);
  };

  const handleCloseDateFilterModal = () => {
    setIsDateFilterModalOpen(false);
  };

  const handleApplyDateFilter = (startDate, endDate) => {
    // handle the apply filter logic here
    console.log(
      `Applying filter with start date ${startDate} and end date ${endDate}`
    );
    handleCloseDateFilterModal();
  };

  return (
    <BrowserRouter>
      <div data-bs-theme={theme}>
        {loading && (
          <div id="loading-overlay" className="show">
            {/* Loading spinner content */}
          </div>
        )}

        <CustomNavbar theme={theme} toggleTheme={toggleTheme} />

        <div id="main-container" className="container-fluid">
          <MainContent handleClearFilters={handleClearFilters} />
          <button onClick={handleOpenDateFilterModal}>
            Open Date Filter Modal
          </button>
        </div>

        <Footer />

        <TwitterFeed />
        <DateFilterModal
          isOpen={isDateFilterModalOpen}
          onClose={handleCloseDateFilterModal}
          onApply={handleApplyDateFilter}
        />
        <ClearFilterToast
          show={showToast}
          onClose={() => setShowToast(false)}
        />

        <button
          id="backToTop"
          className="btn rounded-circle position-fixed bottom-0 end-0 m-3"
        >
          <i className="bi bi-arrow-up-circle-fill"></i>
        </button>
      </div>
    </BrowserRouter>
  );
}

export default App;
