import React from "react";
import { Navbar, Container, Button } from "react-bootstrap";
import "../styles/navbar.css";
import ErrorBoundary from "./ErrorBoundary"; // Import the ErrorBoundary component

export const CustomNavbar = ({ theme, toggleTheme }) => {
  return (
    <ErrorBoundary>
      {" "}
      {/* Wrap the component with ErrorBoundary */}
      <Navbar bg="primary" expand="lg" variant="dark">
        <Container fluid>
          {/* Brand Logo and Title */}
          <Navbar.Brand className="d-flex align-items-center">
            <img
              src="https://cdn.jailbreakchangelogs.xyz/logo/logo_animated.gif"
              alt="Logo"
              id="logo"
              className="header-logo me-2"
            />
            <span id="main-title" className="text-white">
              TimeLine
            </span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {/* Mobile theme toggle */}
            <Button
              id="theme-toggle-mobile"
              className="btn-outline-none me-2 d-lg-none p-2"
              onClick={toggleTheme}
              style={{ fontSize: "1.5rem" }}
              aria-label="Toggle theme"
            >
              <i
                className={`bi bi-${
                  theme === "dark" ? "sun-fill" : "moon-stars-fill"
                }`}
                style={{ fontSize: "1.5rem" }}
              ></i>
            </Button>

            {/* Desktop theme toggle */}
            <Button
              id="theme-toggle-desktop"
              className="btn-outline-none d-none d-lg-block p-2"
              onClick={toggleTheme}
              style={{ fontSize: "1.5rem" }}
              aria-label="Toggle theme"
            >
              <i
                className={`bi bi-${
                  theme === "dark" ? "sun-fill" : "moon-stars-fill"
                }`}
                style={{ fontSize: "1.5rem" }}
              ></i>
            </Button>
          </div>
        </Container>
      </Navbar>
    </ErrorBoundary>
  );
};
