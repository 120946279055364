// In /components/footer/index.jsx
import React from "react";
import { BadimoMessage } from "./BadimoMessage";
import { Contribute } from "./Contribute";
import { FooterAccordion } from "./FooterAccordion";
import { QuickLinks } from "./QuickLinks";

export const Footer = () => {
  return (
    <footer>
      <BadimoMessage />
      <Contribute />
      <FooterAccordion />
      <QuickLinks />
    </footer>
  );
};
