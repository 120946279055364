import React, { useState } from "react";

export const TwitterFeed = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-primary" type="button" onClick={handleShow}>
        Open Twitter Feed
      </button>

      <div
        className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
        tabIndex="-1"
        id="twitterFeedOffcanvas"
        aria-labelledby="twitterFeedOffcanvasLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="twitterFeedOffcanvasLabel">
            Twitter Feed
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* Twitter feed content goes here */}
          <p>This is where I'll integrate the badimo Twitter feed.</p>
        </div>
      </div>
    </>
  );
};
